import { css, ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import { ReactLenis } from '@studio-freight/react-lenis'
import React, { memo } from 'react'

import { MediaContextProvider, mediaStyle } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight2: '#E0DDD4',
      neutralLight1: '#F5F3F0',
      neutralDark2: '#707070',
      neutralDark1: '#2F2F2F',
      primaryDark: '#000000',
      primaryLight: '#FFFFFF',
      danger: '#F23030',
    },
  },
  fontFamily: {
    heading: "'operetta-12', serif",
    label: "'ltc-bodoni-175', serif",
    paragraph: "'tt-commons-pro', sans-serif",
  },
}

export type Styles = typeof styles

const titleStyle = css`
  color: ${theme.colors.variants.primaryDark};
  font-family: ${theme.fontFamily.heading};
  text-transform: uppercase;
`

export const styles = {
  label: css`
    color: ${theme.colors.variants.primaryDark};
    font-family: ${theme.fontFamily.label};
    font-size: 1.5rem;
    font-style: italic;
    line-height: 2.125rem;
    margin: 0 auto 0.25rem;

    @media (max-width: 1199px) {
      font-size: 1.375rem;
      line-height: 1.75rem;
    }
  `,
  tag: css`
    color: ${theme.colors.variants.primaryDark};
    font-family: ${theme.fontFamily.paragraph};
    font-size: 0.875rem;
    font-weight: 300;
    letter-spacing: 0.1em;
    line-height: 1.125rem;
    text-transform: uppercase;
  `,
  title: {
    large: css`
      ${titleStyle}
      font-size: 3.25rem;
      line-height: 3.25rem;

      @media (max-width: 1199px) {
        font-size: 2.25rem;
        line-height: 2.375rem;
      }
    `,
    medium: css`
      ${titleStyle}
      font-size: 3rem;
      line-height: 3.375rem;

      @media (max-width: 1199px) {
        font-size: 2.25rem;
        line-height: 2.375rem;
      }
    `,
    xm: css`
      ${titleStyle}
      font-size: 2.625rem;
      line-height: 3.125rem;

      @media (max-width: 1199px) {
        font-size: 2.25rem;
        line-height: 2.375rem;
      }
    `,
    small: css`
      ${titleStyle}
      font-size: 2rem;
      line-height: 2.75rem;

      @media (max-width: 1199px) {
        font-size: 1.75rem;
        line-height: 1.875rem;
      }
    `,
    xs: css`
      ${titleStyle}
      font-size: 1.5rem;
      line-height: 1.625rem;
    `,
  },
  description: css`
    color: ${theme.colors.variants.neutralDark1};
    font-family: ${theme.fontFamily.paragraph};
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.5rem;
  `,
  img: css`
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    will-change: contents;
  `,
}

interface Props {
  children: any
  pageContext: any
}

export const ThemeProvider = memo(function ThemeProvider({ children }: Props) {
  const isBrowser = typeof window !== 'undefined'

  let smoothScroll = false
  if (isBrowser) smoothScroll = window.innerWidth > 1199 ? true : false

  return (
    <EmotionThemeProvider theme={theme}>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        {smoothScroll ? (
          <ReactLenis root options={{ duration: 1.4 }}>
            {children}
          </ReactLenis>
        ) : (
          children
        )}
      </MediaContextProvider>
    </EmotionThemeProvider>
  )
})
